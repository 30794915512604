import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components'

import queryUniverses from '../queries/universes'
import mutationUniverseActive from '../mutations/universeActive'

const StyledSelect = styled(Select)`
    min-width: 8rem;
    width: 100%;
`

const StyledLink = styled(Link)`
    text-align: left;
    text-decoration: none;
    background: transparent;
    border: 0;
    font-size: 0.8rem;
    padding: 0;
    color: ${props => props.theme.palette.dark};
    font-family: ${props => props.theme.fonts.sans};
    text-transform: uppercase;
`

const OptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2px 8px;
`

const UniverseOption = props => {
    const { innerProps, isDisabled, data } = props
    if (isDisabled) {
        return null
    }
    if (data.value === '') {
        return (
            <OptionWrapper>
                <StyledLink to="/campaign/universes-add/">
                    + Add universe
                </StyledLink>
            </OptionWrapper>
        )
    } else {
        return (
            <OptionWrapper {...innerProps}>
                {data.label}
                <StyledLink to={`/campaign/universes/${data.value}`}>
                    Edit
                </StyledLink>
            </OptionWrapper>
        )
    }
}

class SelectUniverse extends Component {
    setValue = e => {
        this.props.mutate({
            variables: { universe: e.value },
        })
    }
    renderOption = option => {
        return (
            <div>
                {option.label}
                <div>Edit</div>
            </div>
        )
    }
    render() {
        const { data } = this.props
        let value
        let options
        if (!data.loading && data.universes) {
            options = data.universes.edges.map(u => {
                const { name, image, id } = u.node

                if (id === data.userSettings.campaignUniverseActive.id) {
                    value = { label: name, image, value: id }
                }

                return { label: name, image, value: id }
            })
            options.push({ label: '', value: '' })
        }
        return (
            <StyledSelect
                value={value}
                onChange={this.setValue}
                options={options}
                components={{ Option: UniverseOption }}
            />
        )
    }
}

export default compose(
    graphql(queryUniverses),
    graphql(mutationUniverseActive, {
        options: {
            refetchQueries: [
                'Universes',
                'Sites',
                'Factions',
                'Entities',
                'Events',
            ],
        },
    })
)(SelectUniverse)
