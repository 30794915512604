// @flow
import PubSub from 'pubsub-js'
import moment from 'moment'

export default (token: string) => {
    localStorage.setItem('token', token)
    let now = new Date()
    let validThrough = moment(now)
        .add(20, 'm')
        .toDate()
    localStorage.setItem('validThrough', validThrough)
    PubSub.publish('TOKEN_REFRESHED', { token })
}
