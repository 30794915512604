// @flow
import PubSub from 'pubsub-js'

export default () => {
    new Promise((resolve, reject) => {
        const d = new Date()
        localStorage.setItem('lastSeen', d.toString())
        resolve()
    }).then(() => PubSub.publish('ONLINE'))
}
