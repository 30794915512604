import PubSub from 'pubsub-js'
import React, { Component } from 'react'
import styled from 'styled-components'

import Toast from './Toast'

const ToastContainer = styled.div`
    position: fixed;
    bottom: 0rem;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
`

export default class Toasts extends Component {
    constructor() {
        super()
        this.state = { toasts: [], timeout: false, ps: undefined }
    }
    componentDidMount() {
        let ps = PubSub.subscribe('TOAST_ADD', (message, data) => {
            let toasts = [...this.state.toasts]
            toasts.push(data)
            if (this.state.timeout) {
                this.setState({ toasts })
            } else {
                this.setState({ toasts, timeout: true }, () =>
                    setTimeout(this.removeToast, 2000)
                )
            }
        })
        this.setState({ ps })
    }
    removeToast = () => {
        let toasts = [...this.state.toasts]
        toasts.shift()
        if (toasts.length > 0) {
            this.setState({ toasts }, () => setTimeout(this.removeToast, 2000))
        } else {
            this.setState({ toasts, timeout: false })
        }
    }
    componentWillUnmount() {
        if (this.state.ps) {
            PubSub.unsubscribe(this.state.ps)
        }
    }
    render() {
        return (
            <ToastContainer>
                {this.state.toasts.map((t, i) => <Toast key={i} data={t} />)}
            </ToastContainer>
        )
    }
}
