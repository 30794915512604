import PubSub from 'pubsub-js'

import { wsBase } from './settings'

const socket = new WebSocket(`${wsBase}socket`, [], {
    connectionTimeout: 1000,
})

export default () => {
    PubSub.subscribe('SOCKET', (message, data) => {
        data.token = localStorage.getItem('token')
        socket.send(JSON.stringify(data))
    })
    socket.addEventListener('message', e => {
        const data = JSON.parse(e.data)
        if (process.env.NODE_ENV === 'development') {
            console.log('SOCKET', data)

            PubSub.publish('STORIES', { refetch: true })
        }
    })
}
