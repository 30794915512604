// @flow
import moment from 'moment'
import PubSub from 'pubsub-js'
import logout from './logout'

export default () => {
    if (localStorage.getItem('token')) {
        let lastSeen = new Date(localStorage.getItem('lastSeen') || '')
        let validThrough = new Date(localStorage.getItem('validThrough') || '')

        let now = new Date()

        // Refresh if the token is about to be invalid
        let duration = (validThrough - now) / 1000 / 60
        if (duration < 2) {
            PubSub.publish('LOGIN_REFRESH')
        }

        // Logout if idle for 20 min
        let rest = Math.ceil(
            (moment(lastSeen)
                .add(20, 'm')
                .toDate() -
                now) /
                1000 /
                60
        )
        if (rest <= 0) {
            logout()
        }

        // Force logout when token is invalid
        if (validThrough <= now) {
            logout()
        }
    }
}
