import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import SelectUniverse from './SelectUniverse'
import logout from '../utils/logout'

const StyledLink = styled(Link)`
    text-decoration: none;
    font-size: 0.8rem;
    color: ${props => props.theme.palette.light};
    font-family: ${props => props.theme.fonts.sans};
    text-transform: uppercase;
    padding: 0.6rem;
    display: block;
    outline: none;
`

const LogoutButton = styled.button`
    text-decoration: none;
    font-size: 0.8rem;
    color: ${props => props.theme.palette.light};
    font-family: ${props => props.theme.fonts.sans};
    text-transform: uppercase;
    padding: 0.6rem;
    display: block;

    width: 100%;
    text-align: left;
    cursor: pointer;
    background: transparent;
    border: 0;
    font-weight: ${props => props.theme.fonts.regular};
`

const Nav = styled.nav`
    background: linear-gradient(
        135deg,
        ${props => props.theme.palette.dark} 30%,
        ${props => props.theme.palette.primary} 100%
    );

    position: sticky;
    top: 0;

    z-index: 1;

    display: grid;
    grid-template-areas:
        'start selector sink'
        'menu menu menu';
    @media (min-width: ${props => props.theme.breakPoint.medium}) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 12rem;
        height: 100vh;
    }
`

const Start = styled.ul`
    grid-area: start;
    margin: 0.4rem;
    padding: 0;
    list-style-type: none;
    @media (min-width: ${props => props.theme.breakPoint.medium}) {
        margin: 1rem 0;
    }
`

const Selector = styled.div`
    grid-area: selector;
    margin: 0 0.6rem;
`

const CampaignItems = styled.ul`
    grid-area: menu;

    flex: 1;
    margin: 0.4rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    @media (min-width: ${props => props.theme.breakPoint.medium}) {
        flex-direction: column;
        justify-content: start;
        margin: 1rem 0;
    }
`

const Sink = styled.ul`
    grid-area: sink;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 0.4rem;
    padding: 0;
    list-style-type: none;
    @media (min-width: ${props => props.theme.breakPoint.medium}) {
        flex-direction: column;
        justify-content: start;
        margin: 1rem 0;
    }
`
const Item = styled.li``

export default props => (
    <Nav>
        <Start>
            <Item>
                <StyledLink to="/">Start</StyledLink>
            </Item>
        </Start>
        <Selector>
            <SelectUniverse />
        </Selector>
        <CampaignItems>
            <Item>
                <StyledLink to="/campaign/sites">Sites</StyledLink>
            </Item>
            <Item>
                <StyledLink to="/campaign/factions">Factions</StyledLink>
            </Item>
            <Item>
                <StyledLink to="/campaign/entities">Entities</StyledLink>
            </Item>
            <Item>
                <StyledLink to="/campaign/events">Events</StyledLink>
            </Item>
            <Item>
                <StyledLink to="/campaign/stories">Stories</StyledLink>
            </Item>
        </CampaignItems>
        <Sink>
            <Item>
                <StyledLink to="/settings">Settings</StyledLink>
            </Item>
            <Item>
                <LogoutButton onClick={logout}>Logout</LogoutButton>
            </Item>
        </Sink>
    </Nav>
)
