import PubSub from 'pubsub-js'
import React, { Component, Suspense } from 'react'
import { graphql } from 'react-apollo'
import { Route, withRouter, Switch } from 'react-router-dom'
import styled from 'styled-components'

import Navigation from './navigation/Navigation'
import PublicNavigation, { PublicFooter } from './navigation/PublicNavigation'
import Toasts from './notifications/Toasts'
import checkLogin from './utils/checkLogin'
import logout from './utils/logout'
import refreshToken from './utils/refreshToken'
import sendPing from './utils/sendPing'
import mutationRefreshToken from './mutations/refreshToken'
import CookieBanner from './styled/CookieBanner'

const LandingPage = React.lazy(() => import('./login/LandingPage'))
const Registration = React.lazy(() => import('./registration/Registration'))
const ResetPassword = React.lazy(() => import('./login/ResetPassword'))
const ResetPasswordConfirm = React.lazy(() =>
    import('./login/ResetPasswordConfirm')
)
const Imprint = React.lazy(() => import('./login/Imprint'))
const PrivacyPolicy = React.lazy(() => import('./login/PrivacyPolicy'))
const TermsOfService = React.lazy(() => import('./login/TermsOfService'))
const Campaign = React.lazy(() => import('./campaign/Campaign'))
const UserSettings = React.lazy(() => import('./usersettings/UserSettings'))
const Universes = React.lazy(() => import('./campaign/universes/Universes'))
const UniverseAdd = React.lazy(() => import('./campaign/universes/UniverseAdd'))
const Universe = React.lazy(() => import('./campaign/universes/Universe'))
const Events = React.lazy(() => import('./campaign/events/Events'))
const EventAdd = React.lazy(() => import('./campaign/events/EventAdd'))
const Event = React.lazy(() => import('./campaign/events/Event'))
const Sites = React.lazy(() => import('./campaign/sites/Sites'))
const SiteAdd = React.lazy(() => import('./campaign/sites/SiteAdd'))
const Site = React.lazy(() => import('./campaign/sites/Site'))
const Factions = React.lazy(() => import('./campaign/factions/Factions'))
const FactionAdd = React.lazy(() => import('./campaign/factions/FactionAdd'))
const Faction = React.lazy(() => import('./campaign/factions/Faction'))
const Entities = React.lazy(() => import('./campaign/entities/Entities'))
const EntityAdd = React.lazy(() => import('./campaign/entities/EntityAdd'))
const Entity = React.lazy(() => import('./campaign/entities/Entity'))
const Stories = React.lazy(() => import('./campaign/stories/Stories'))
const StoryAdd = React.lazy(() => import('./campaign/stories/StoryAdd'))
const Story = React.lazy(() => import('./campaign/stories/Story'))

const AppFrame = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @media (min-width: ${props => props.theme.breakPoint.medium}) {
        flex-direction: row;
    }
`

const PublicAppFrame = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`

const Loading = () => <div />

class App extends Component {
    constructor() {
        super()

        let token = localStorage.getItem('token')
        if (token) {
            this.state = { loggedIn: true }
        } else {
            this.state = { loggedIn: false }
        }

        PubSub.subscribe('LOGIN', () => {
            this.setState({ loggedIn: true })
        })
        PubSub.subscribe('LOGOUT', () => {
            this.setState({ loggedIn: false })
        })
        PubSub.subscribe('LOGIN_REFRESH', () => {
            let token = localStorage.getItem('token')
            this.props.mutate({ variables: { token } }).then(res => {
                if (res.data.refreshToken.success) {
                    refreshToken(res.data.refreshToken.token)
                } else {
                    logout()
                }
            })
        })
    }
    componentDidMount() {
        this.props.history.listen(() => {
            window.ga('set', 'page', window.location.pathname)
            window.ga('send', 'pageview')
        })
        sendPing()
        document.addEventListener('keypress', e => sendPing())
        document.addEventListener('click', e => sendPing())
        checkLogin()
        setInterval(checkLogin, 1000)
    }
    render() {
        const { loggedIn } = this.state
        if (!loggedIn) {
            return (
                <PublicAppFrame>
                    <PublicNavigation />

                    <Suspense fallback={<Loading />}>
                        <Switch>
                            <Route path="/" exact component={LandingPage} />
                            <Route
                                path="/registration"
                                exact
                                component={Registration}
                            />
                            <Route
                                path="/reset-password"
                                exact
                                component={ResetPassword}
                            />
                            <Route
                                path="/reset/:userId/:token"
                                component={ResetPasswordConfirm}
                            />
                            <Route path="/impressum" component={Imprint} />
                            <Route
                                path="/privacy-policy"
                                component={PrivacyPolicy}
                            />
                            <Route
                                path="/terms-of-service"
                                component={TermsOfService}
                            />
                        </Switch>
                    </Suspense>
                    <Toasts />
                    <CookieBanner
                        message="This website uses cookies to ensure you get the best experience on our website."
                        dismissOnScroll={false}
                        onAccept={() => {}}
                        buttonMessage="Accept"
                        cookie="user-has-accepted-cookies"
                        disableStyle={true}
                    />
                    <PublicFooter />
                </PublicAppFrame>
            )
        }
        return (
            <AppFrame>
                <Navigation
                    version={this.props.version}
                    fullscreen={this.props.fullscreen}
                    toggleFullscreen={this.props.toggleFullscreen}
                />
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route path="/" exact component={Campaign} />
                        <Route path="/settings" component={UserSettings} />
                        <Route
                            path="/campaign/universes"
                            exact
                            component={Universes}
                        />
                        <Route
                            path="/campaign/universes-add"
                            component={UniverseAdd}
                        />
                        <Route
                            path="/campaign/universes/:id"
                            component={Universe}
                        />
                        <Route
                            path="/campaign/events"
                            exact
                            component={Events}
                        />
                        <Route
                            path="/campaign/events-add"
                            component={EventAdd}
                        />
                        <Route path="/campaign/events/:id" component={Event} />
                        <Route path="/campaign/sites" exact component={Sites} />
                        <Route path="/campaign/sites-add" component={SiteAdd} />
                        <Route path="/campaign/sites/:id" component={Site} />
                        <Route
                            path="/campaign/factions"
                            exact
                            component={Factions}
                        />
                        <Route
                            path="/campaign/factions-add"
                            component={FactionAdd}
                        />
                        <Route
                            path="/campaign/factions/:id"
                            component={Faction}
                        />
                        <Route
                            path="/campaign/entities"
                            exact
                            component={Entities}
                        />
                        <Route
                            path="/campaign/entities-add"
                            component={EntityAdd}
                        />
                        <Route
                            path="/campaign/entities/:id"
                            component={Entity}
                        />
                        <Route
                            path="/campaign/stories"
                            exact
                            component={Stories}
                        />
                        <Route
                            path="/campaign/stories-add"
                            component={StoryAdd}
                        />
                        <Route path="/campaign/stories/:id" component={Story} />
                    </Switch>
                </Suspense>
                <Toasts />
                <CookieBanner
                    message="This website uses cookies to ensure you get the best experience on our website."
                    dismissOnScroll={false}
                    onAccept={() => {}}
                    buttonMessage="Accept"
                    cookie="user-has-accepted-cookies"
                    disableStyle={true}
                />
            </AppFrame>
        )
    }
}

export default withRouter(graphql(mutationRefreshToken)(App))
