import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledLabel = styled.label`
  margin: 0.2rem;
  padding: 0.4rem 0.8rem;
  border-radius: ${props => props.theme.border.radius};
  transition: all 1s ease-in;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.sans};
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
  border: 0;
  color: ${props => props.theme.palette.light};
  ${props =>
    props.disabled
      ? css`
          background: linear-gradient(
            119deg,
            ${props => props.theme.palette.dark} 0%,
            ${props => props.theme.palette.gray} 13%,
            ${props =>
                props.danger
                  ? props.theme.palette.danger
                  : props.theme.palette.gray}
              95%
          );
          cursor: not-allowed;
        `
      : css`
          background: linear-gradient(
            119deg,
            ${props => props.theme.palette.dark} 0%,
            ${props => !props.danger && props.theme.palette.primary} 13%,
            ${props =>
                props.danger
                  ? props.theme.palette.danger
                  : props.theme.palette.secondary}
              95%
          );
        `} &:hover {
    color: white;
  }
`

const StyledLink = styled(Link)`
  margin: 0.2rem;
  padding: 0.4rem 0.8rem;
  border-radius: ${props => props.theme.border.radius};
  transition: all 1s ease-in;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.sans};
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
  border: 0;
  color: ${props => props.theme.palette.light};
  text-decoration: none;
  ${props =>
    props.disabled
      ? css`
          background: linear-gradient(
            119deg,
            ${props => props.theme.palette.dark} 0%,
            ${props => props.theme.palette.gray} 13%,
            ${props =>
                props.danger
                  ? props.theme.palette.danger
                  : props.theme.palette.gray}
              95%
          );
          cursor: not-allowed;
        `
      : css`
          background: linear-gradient(
            119deg,
            ${props => props.theme.palette.dark} 0%,
            ${props => !props.danger && props.theme.palette.primary} 13%,
            ${props =>
                props.danger
                  ? props.theme.palette.danger
                  : props.theme.palette.secondary}
              95%
          );
        `} &:hover {
    color: white;
  }
`

export default props => {
  const { animated, ...localProps } = props
  if (typeof props.to !== 'undefined') {
    return <StyledLink {...localProps} />
  }
  return <StyledLink as="button" {...localProps} />
}
