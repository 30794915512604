export const dropboxClientId = 'vccfc5xbgzfwu4x'

export const lfxBase = process.env.REACT_APP_LFX_BASE
export const apiBase = process.env.REACT_APP_API_BASE
export const mediaBase = process.env.REACT_APP_MEDIA_BASE
export const publicBase = process.env.REACT_APP_PUBLIC_BASE
export const wsBase = process.env.REACT_APP_WS_BASE

let _version
try {
    _version = document.getElementById('version').getAttribute('data-commit')
} catch (e) {
    _version = 'NONE'
}
export const version = _version
