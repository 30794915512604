import React from 'react'
import styled, { css } from 'styled-components'

const Toast = styled.div`
  position: relative;
  ${props =>
    props.type === 'error'
      ? css`
          background: linear-gradient(
            135deg,
            ${props => props.theme.palette.dark} 30%,
            ${props => props.theme.palette.danger} 100%
          );
        `
      : css`
          background: linear-gradient(
            135deg,
            ${props => props.theme.palette.dark} 30%,
            ${props => props.theme.palette.primary} 100%
          );
        `} color: ${props => props.theme.palette.light};
  border: ${props => props.theme.border.style};
  border-radius: ${props => props.theme.border.radius};
  padding: 0.6rem 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: ${props => props.theme.fontSize.small};
  animation: 0.4s ease-out 0s slideIn;

  @keyframes slideIn {
    0% {
      transform: translateY(10rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }
`

export default props => (
  <Toast type={props.data.type}>{props.data.message}</Toast>
)
