import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import React, { Fragment } from 'react'
import { ApolloProvider } from 'react-apollo'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import registerServiceWorker from './registerServiceWorker'
import { apiBase } from './settings'
import stream from './sockets'
import App from './App'
import theme, { GlobalStyle } from './theme'

stream()

const httpLink = createHttpLink({
    uri: `${apiBase}graphql`,
})

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token')
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <ThemeProvider theme={theme}>
                <Fragment>
                    <GlobalStyle />
                    <App />
                </Fragment>
            </ThemeProvider>
        </Router>
    </ApolloProvider>,
    document.getElementById('root')
)

registerServiceWorker()
