import { createGlobalStyle } from 'styled-components'
import 'typeface-montserrat'

import 'cropperjs/dist/cropper.css'

const theme = {
    fonts: {
        sans: "'Montserrat', sans-serif",
        headline: "'Montserrat', sans-serif",
        regular: '300',
        bold: '600',
    },
    fontSize: {
        small: '0.8rem',
        default: '1rem',
        big: '1.4rem',
    },
    palette: {
        dark: '#000000',
        light: '#FFFFFF',
        gray: '#444444',
        primary: '#004A57',
        secondary: '#006F82',
        danger: '#5A1C23',

        alpha: {
            light: 'rgba(241, 241, 241, 0.8)',
            dark: 'rgba(0, 0, 0, 0.4)',
        },
    },
    border: {
        style: '1px solid #000',
        radius: '3px',
    },
    shadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    shadowHover: '0 4px 14px 0 rgba(0,0,0,0.3)',

    transition: 'all 0.2s linear',

    contentMaxWidth: '60rem',
    breakPoint: {
        small: '30rem',
        medium: '60rem',
        large: '100rem',
    },
}

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: 14px;
        @media (min-width: 60rem) {
            font-size: 16px;
        }
    }

    body{
        font-family: ${theme.fonts.sans};
        font-weight: ${theme.fonts.regular};
        hyphens: auto;
        margin: 0;
    }
    @keyframes ButtonAnimation {
        0% {
            color: ${theme.palette.light};
        }
        50% {
            color: #eee;
        }
        100% {
            color: ${theme.palette.light};
        }
    }
    .react-tabs__tab-list {
        margin: 0 0 1rem 0;
        padding: 1rem 0 0 0;
        display: flex;
        list-style-type: none;
        border-top: 1px solid ${theme.palette.dark};
    }
    .react-tabs__tab {
        font-weight: ${theme.fonts.bold};
        text-transform: uppercase;
        margin-right: 1rem;
        cursor: pointer;
        border-bottom: 3px solid transparent;
        transition: ${theme.transition};

        &:hover {
            color: ${theme.palette.secondary};
        }
    }
    .react-tabs__tab--selected {
        border-bottom: 3px solid ${theme.palette.dark};
        &:hover {
            color: ${theme.palette.dark};
        }
    }

    .modal-overlay {
        background: ${theme.palette.alpha.dark};
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }
    .ReactModal__Content {
        display: flex;
        flex-direction: column;
    }
`

export default theme
