import gql from 'graphql-tag'

export default gql`
    mutation RefreshToken($token: String!) {
        refreshToken(input: { token: $token }) {
            success
            token
        }
    }
`
