import gql from 'graphql-tag'

export default gql`
    query Universes($deleted: Boolean) {
        universes(deleted: $deleted) {
            edges {
                node {
                    id
                    deleted
                    name
                    image
                }
            }
        }
        userSettings {
            id
            campaignUniverseActive {
                id
            }
        }
    }
`
