import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Button from '../styled/Button'

const NavigationFrame = styled.div`
    color: ${props => props.theme.palette.light};
    background: ${props => props.theme.palette.dark};
`

const StyledLink = styled(Link)`
    color: ${props => props.theme.palette.light};
    text-decoration: none;
    font-size: ${props => props.theme.fontSize.small};
    margin: 1rem 0.5rem;
    transition: 0.2s color;
    text-transform: uppercase;

    &:hover {
        color: ${props => props.theme.palette.alpha.light};
    }
`

const Container = styled.div`
    max-width: 60rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${props => props.theme.breakPoint.small}) {
        flex-direction: row;
        justify-content: flex-end;
    }
`

export const PublicFooter = props => (
    <NavigationFrame>
        <Container style={{ padding: '1rem' }}>
            <StyledLink to="/terms-of-service">Terms of Service</StyledLink>
            <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
            <StyledLink to="/impressum">Impressum</StyledLink>
        </Container>
    </NavigationFrame>
)

export default props => (
    <NavigationFrame>
        <Container>
            <StyledLink to="/">Start</StyledLink>
            <Button animated to="/registration">
                Signup
            </Button>
        </Container>
    </NavigationFrame>
)
