import CookieBanner from 'react-cookie-banner'
import styled from 'styled-components'

export default styled(CookieBanner)`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: ${props => props.theme.palette.dark};
    color: ${props => props.theme.palette.light};
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .cookie-message {
        padding-right: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: ${props => props.theme.breakPoint.small}) {
        flex-direction: row;
        .cookie-message {
            padding-right: initial;
            padding-bottom: initial;
        }
    }

    .button-close {
        min-width: 8rem;
        width: 100%;
        @media (min-width: ${props => props.theme.breakPoint.small}) {
            width: initial;
        }
        margin: 0.2rem;
        padding: 0.4rem 0.8rem;
        border-radius: ${props => props.theme.border.radius};
        transition: all 1s;
        cursor: pointer;
        font-family: ${props => props.theme.fonts.sans};
        font-size: 0.8rem;
        font-weight: 300;
        text-transform: uppercase;
        border: 0;
        color: ${props => props.theme.palette.dark};
        background: ${props => props.theme.palette.light};
    }
`
